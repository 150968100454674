<template>
  <div class="home_top">
    <div class="inner inner1">
      <div class="left_item">
        <div class="wbg">
          <h1>Get Quick Cash Anytime, </h1>
          <h1>Anywhere.</h1>
          <h4>Never go broke again. No long queues. No bulky documents.</h4>
          <h4>No long wait times. Just your smartphone and PesoGo VIP App.</h4>
        </div>
        <div class="item_download" @click="downloadApp()">
          <img src="@/assets/images/Google.png" alt="">
        </div>
      </div>
      <div class="right_item">
        <!-- <img src="@/assets/images/banner01.png" alt=""> -->
      </div>
    </div>
    <div class="inner inner2">
      <div class="flex fw-w jc-sb">
        <div v-for="(item,index) in leftItemList2" :key="index" class="content" :class="inner_2Class(index)">
          <h1>{{ item.title }}</h1>
          <div v-for="(item2,index2) in item.list2" :key="index2" class="flex content2 ai-c">
            <img :src="item2.img">
            <p>{{ item2.txt }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="inner inner3">
      <h3>What are the advantages of PesoGo VIP?</h3>
      <div class="flex fd-c">
        <div v-for="(item,index) in inner_3items" :key="index" class="content flex jc-sb" :class="index === 1 ?'fd-r':''">
          <img :src="item.img">
          <div class="content2 flex fd-c jc-sb">
            <div v-for="(item2,index2) in item.list2" :key="index2">
              <h1>{{ item2.title }}</h1>
              <p>{{ item2.txt }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="inner inner4">
      <div class="title">
        <h3>How can I get a loan?</h3>
      </div>
      <div class="flex fw-w">
        <div v-for="(item,index) in inner_4items" :key="index" class="content flex">
          <img :src="item.img">
          <div class="" :style="{ width:'100%'} ">
            <h1>{{ item.title }}</h1>
            <p>{{ item.txt }}</p>
          </div>
        </div>
      </div>
    </div>
    <div id="selector1" class="inner inner7">
      <h3>What Users Are Saying</h3>
      <div class="flex al-c jc-c">
        <!-- <button class="carousel-button" :disabled="currentIndex === 0" @click="previousSlide"><img src="@/assets/images/loanImg/black_left.png"></button> -->
        <div class="carousel-container">
          <div class="carousel">
            <transition-group name="fade" tag="div" class="carousel-wrapper">
              <div v-for="(item) in visibleItems" :key="item.id" class="carousel-item">
                <img :src="item.img">
                <div>
                  <h1>{{ item.txt }}</h1>
                  <p>{{ item.txt2 }}</p>
                </div>
              </div>
            </transition-group>
          </div>
        </div>
        <button class="carousel-button" @click="nextSlide"><img src="@/assets/images/loanImg/black_right.png"></button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      leftItemList: [
        { txt: 'Altruism', img: require('@/assets/images/Product01.png') },
        { txt: 'Tenacity', img: require('@/assets/images/Product02.png') },
        { txt: 'Innovate', txt2: 'mortgage', img: require('@/assets/images/Product03.png') },
        { txt: 'Integrity', img: require('@/assets/images/Product04.png') },
        { txt: 'Respect', img: require('@/assets/images/Product05.png') }
      ],
      leftItemList2: [
        { title: 'What can you get?', list2: [{ img: require('@/assets/images/list01.png'), txt: 'From 1,000 ₱ up to 50,000 ₱' }, { img: require('@/assets/images/list02.png'), txt: 'Repayment Term：0-180 days' }, { img: require('@/assets/images/list03.png'), txt: 'Annual interest rate：10%-35%' }] },
        { title: 'Who is eligible to get a loan?', list2: [{ img: require('@/assets/images/list04.png'), txt: 'Age 18-60 years' }, { img: require('@/assets/images/list05.png'), txt: 'Philippines citizen' }, { img: require('@/assets/images/list06.png'), txt: 'Employed or Business-owner' }] }
      ],
      inner5ItemLeftList: [
        { title: 'Online service', txt: 'You can find online services in ', txt2: 'the PesoGo VIP app.', img: require('@/assets/images/ng_1.png') },
        { title: 'E-mail', txt: 'tigercreditapp@gmail.com', img: require('@/assets/images/ng_2.png') },
        { title: 'Customer service hours', txt: '9AM-6PM', img: require('@/assets/images/ng_3.png') }
      ],
      items: [
        // 您的轮播内容数据
        { id: '1', txt: 'Improve living standards and achieve \n shopping freedom', txt2: '“I’ve been using PesoGo VIP way back in 2022 and \n I got my first phone on Lazada. It is really a big help \n because they offer low interest than in any other \n lending company and it’s very reliable and easy to \n use. I wish this company would grow and help others \n like me to get what they want and pay in installments.”', img: require('@/assets/images/Product01.png') },
        { id: '2', txt: 'Serving millions of users, with lower \n interest rates than other loan companies', txt2: '“PesoGo VIP has the lowest interest rate of all \n the lending companies. I’ve been a customer for \n 1 yrs and happy with their service. I was able to \n purchase appliances and gadgets through them. \n Highly recommended!”', img: require('@/assets/images/Product02.png') },
        { id: '3', txt: 'Reduce financial burden and help you \n achieve your dreams', txt2: '“Whenever I need funds for emergencies, shopping \n or travel, PesoGo VIP always comes to the rescue. \n This is, by far, the best, affordable and hassle-free \n online loan experience I have ever had. With \n PesoGo VIP,People can turn their dreams into reality.”', img: require('@/assets/images/Product03.png') }
      ],
      visibleItemCount: 2,
      currentIndex: 0,
      inner_3items: [
        { img: require('@/assets/images/list-left.png'), list2: [{ title: 'High Credit Limit', txt: 'You can borrow up to P30,000 if you keep a good credit record with us.' }, { title: 'No Hidden Fees', txt: 'We have a transparent tariff scale, the final amount and payments are known in advance.' }, { title: 'Fast Application', txt: '3-minute application process and assessment within 24 hours.' }] },
        { img: require('@/assets/images/list-right.png'), list2: [{ title: 'Fast payouts', txt: 'We send money to the account in real time. From 9 AM to 9 PM. Everyday' }, { title: 'We are customer focused', txt: 'Our goal is to provide the best customer support system. Never feel alone. Feel safe instead.' }, { title: 'We protect your credit history', txt: 'We have a simple system for extending the loan. We will do our best to help you pay without penalty overpayments and increase the level of amounts allowed for a loan.' }] }
      ],
      inner_4items: [
        { img: require('@/assets/images/list07.png'), title: 'Register and verify', txt: 'Download PesoGo VIP, register with your \n phone number and fill in your personal information \n for identity verification.' },
        { img: require('@/assets/images/list10.png'), title: 'Apply for a loan', txt: 'You can choose the loan amount according \n to your needs, and add an account to \n receive money. You can apply for a loan and \n view the verification results.' },
        { img: require('@/assets/images/list08.png'), title: 'Get a loant', txt: 'We complete the verification process in 5 minutes. \n If approved, we will transfer money to your account.' },
        { img: require('@/assets/images/list11.png'), title: 'Pay off the loan on time', txt: 'To increase the credit limit and avoid \n penalties, you must repay the loans on time \n before the due dates.' },
        { img: require('@/assets/images/list09.png'), title: 'Reapply for a loan', txt: 'We are happy to help you achieve the life of \n your dream and look forward to helping you again.' }
      ],
      inner6ItemLeftList: [
        { title: 'apexlendinghelp@outlook.com', img: require('@/assets/images/email.png') },
        { title: '78 Ogunnusi Road, Ojodu Berger, Lagos State.', img: require('@/assets/images/address.png') },
        { title: '9AM-5PM', img: require('@/assets/images/time.png') }
      ]
    }
  },
  computed: {
    visibleItems() {
      return this.items.slice(this.currentIndex, this.currentIndex + this.visibleItemCount)
    }
  },
  created() {
    // setInterval(() => {
    //   this.nextSlide()
    // }, 4)
  },
  methods: {
    inner_2Class(index) {
      if (index === 4) {
        return 'content2'
      } else if (index % 2 !== 0) {
        return 'text_r'
      }
    },

    downloadApp() {
      window.open('https://play.google.com/store/apps/details?id=pg.vip.loan.rapid', '_blank')
    },
    previousSlide() {
      if (this.currentIndex > 0) {
        this.currentIndex--
      }
    },
    nextSlide() {
      if (this.currentIndex < this.items.length - this.visibleItemCount) {
        this.currentIndex++
      } else {
        this.currentIndex--
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .home_top{
    min-width: 1200px;
    background: #fff;
    white-space: pre-line;
    .inner{
      width: 100%;
      padding:60px calc((100% - 1100px) / 2);
      margin: 0 auto;
    }
    .inner1{
      // background: linear-gradient(258deg, #43C5D6 0%, #4484EC 100%);
      padding:60px calc((100% - 1200px) / 2);
      background: url("~@/assets/images/loanImg/inner1_bg.png") no-repeat;
      background-size: 100% 100%;
      height: 550px;
      display: flex;
      justify-content: space-between;
      .left_item{
        .wbg{
          padding: 40px 25px 30px;
          background: #FFFFFF99;
          border-radius: 20px;
        }
        box-sizing: border-box;
        border-radius: 10px;
        h1{
          font-size: 45px;
          color: black;
          font-weight: 700;
          // margin-bottom: 10px;
          line-height: 60px;
          span{
            color: #00BB53;
          }
        }
        h4{
          font-size: 16px;
          line-height: 30px;
          color: #666666;
          font-weight: normal;
          // margin: 0px 0;
        }
        & h4:nth-of-type(1){
          margin-top: 40px;
        }
        .item_info{
          margin: 20px 0;
          display: flex;
          height: 170px;
          .info_img1{
            width: 40px;
          }
          .info_tips{
            display: flex;
            flex-direction: column;
            p{
              flex: 1;
              display: flex;
              box-sizing: border-box;
              // margin-left: 10px;
              font-size: 12px;
              color: #666666;
              align-items: center;
              img{
                margin-right: 10px;
              }
            }
          }
          .info_img2{
            width: 135px;
            height: 135px;
          }
        }
        .item_download{
          padding-left: 22px;
          cursor: pointer;
          margin-top: 70px;
          color: white;
          border-radius: 5px;
          img{
            width: 190px;
            height: 60px;
            margin-right: 5px;
          }
        }
      }
      .right_item{
        img{
          height: 376px;
          margin-right: 30px;
        }
      }
    }
    .inner2{
      position: relative;
      padding:100px calc((100% - 1100px) / 2) 60px;
      background: linear-gradient(86deg, #013799 0%, #0071D6 100%);
      .content{
        // width: 35%;
        // margin: 0 10px;
        // padding: 80px 0;
        width: 40%;
        text-align: left;
        color:white;
        h1{
          font-size: 30px;
          // line-height: 50px;
          margin:0 0 50px 0;
        }
        p{
          text-align: left;
          font-size: 14px;
          line-height: 20px;
          margin-left: 20px;
          font-weight: bold;
        }
      }
      .content2{
        width: 100%;
        text-align: center;
        background: #FFFFFF;
        color: black;
        padding: 15px 15px;
        border-radius: 10px;
        margin-bottom: 25px;
        img{
          width: 40px;
        }
      }
    }
    .inner3{
      position: relative;
      padding:100px calc((100% - 1100px) / 2) 60px;
      h3{
        font-size: 30px;
        color: black;
        margin-bottom: 40px;
      }
      .content{
        // width: 35%;
        // margin: 0 10px;
        // padding: 80px 0;
        width: 100%;
        text-align: left;
        color:white;
        margin-bottom: 60px;
        h1{
          font-size: 22px;
          line-height: 50px;
          // margin:0 0 50px 0;
          position: relative;
          &::before{
            // position: absolute;
            display: inline-block;
            content:'';
            width: 10px;
            height: 10px;
            border-radius: 5px;
            background: #0071D6;
            margin:0 10px 5px 0;
          }
        }
        p{
          text-align: left;
          font-size: 14px;
          line-height: 20px;
          font-weight: bold;
          width: 70%;
          margin-left: 20px;
        }
        img{
          width: 500px;
          margin-left: -36px;
        }
      }
      .content2{
        width: 42%;
        text-align: left;
        color: black;
        padding: 45px 0 55px;
        margin-right: 11px;
        p{
          color: #666666;
          font-weight: 100 !important;
          line-height: 20px;
        }
      }
    }
    .inner7{
      background: #F1F5FF;
      padding: 70px calc((100% - 1100px) / 2) 40px;
      h3{
        font-size: 30px;
        color: black;
        text-align: left;
        // margin-bottom: 20px;
        position: relative;
        // &::after{
        //   display: block;
        //   content:'';
        //   width: 80px;
        //   height: 5px;
        //   background: #00BB53;
        //   margin: 20px auto 0;
        // }
      }
      p{
        padding: 10px 20px;
        font-size: 12px;
        line-height: 24px;
        color: #666666;
        text-align: left;
      }
      .carousel-container {
        position: relative;
        width: 100%;
        height: 340px;
      }
      .carousel {
        display: flex;
        overflow: hidden;
        height: 100%;
      }
      .carousel-wrapper {
        display: flex;
        width: 100%;
        justify-content:space-around;
        align-items: center;
      }
      .carousel-item {
        min-width: 480px !important;
        height: 200px;
        display: flex;
        align-items: center;
        background: #FFFFFF;
        color: #fff;
        box-shadow: 0px 3px 15px 1px rgba(93,93,93,0.16);
        border-radius: 5px;
        font-size: 18px;
        padding: 0 20px;
        h1{
          color: black;
          font-size: 14px;
          line-height: 18px;
          text-align:  left;
          padding: 0 20px;
        }
        p{
          font-size: 12px;
          line-height: 16px;
        }
        img{
          width: 100px;
          height: 100px;
        }
      }
      .carousel-button {
        // position: absolute;
        // padding: 20px;
        font-size: 20px;
        border: none;
        background-color: transparent;
        color: #555;
        outline: none;
        cursor: pointer;
        img{
          width: 70px;
        }
      }
      .fade-enter-active,
      .fade-leave-active {
        transition: opacity 0.2s, transform 0.2s;
      }

      .fade-enter,
      .fade-leave-to {
        opacity: 0;
        transform: translateX(300px);
      }
    }
    .inner4{
      position: relative;
      padding:60px calc((100% - 1100px) / 2) ;
      background: linear-gradient(49deg, #1DBAA1 0%, #0BA5C9 100%);
      .title{
        p{
          color: #666666;
          font-size: 16px;
          margin: 0;
        }
        h3{
          font-size: 30px;
          color: white;
          text-align: left;
          position: relative;
          margin-bottom: 50px;
          // &::after{
          //   display: block;
          //   content:'';
          //   width: 80px;
          //   height: 5px;
          //   background: #00BB53;
          //   margin: 20px auto 0;
          // }
        }
      }
      .content{
        width: 50%;
        text-align: left;
        color: white;
        margin-bottom: 15px;
        img{
          width: 45px;
          height: 45px;
          margin-right: 20px;
        }
        h1{
          font-size: 22px;
          line-height: 45px;
          // line-height: 50px;
          // margin:0  20px;
        }
        p{
          text-align: left;
          font-size: 12px;
          line-height: 20px;
        }
      }
    }
    .inner6{
      background: #F7F7F7;
      padding:70px calc((100% - 1200px) / 2) ;
      .inner6_item{
        display: flex;
        align-items: center;
        width: 1200px;
        .inner6_item_left{
          z-index: 2;
          border-radius: 10px;
          background: #333333;
          color: #ffffff;
          padding:60px 0px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          box-sizing: border-box;
          width: 50%;
          h1{
            font-size: 45px;
            text-align:center;
            margin-bottom: 26px;
            width: auto;
          }
          .inner6_item_left_list{
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            img{
              width: 24px;
              height: 24px;
              margin-right: 18px;
            }
          }

        }
          .inner6_item_right{
            width: 55%;
            z-index: 1;
            margin-left: -40px;
            img{
              padding: 2px;
              // border: 2px dashed rgb(206, 206, 206);
              width: 100%;
            }
          }
      }

    }
  }
  @media only screen and (max-width: 720px){
    .home_top{
      min-width: calc(100vw) !important;
      .inner{
        min-width: calc(100vw) !important;
        display: flex;
        flex-wrap: wrap;
      }
      .inner1{
        padding: 0px calc((100% - 1200px) / 2) 0;
        height: 550px;
        position: relative;
        background-size: 100% auto;
        .left_item{
          margin: 0px 10px 10px;
          // height: 500px;
          padding: 180px 0px 0 0 !important;
          flex: 1;
          h1{
            font-size: 25px;
            line-height: 35px;
            // line-height: 50px;
            color: white !important;
          }
          h4{
            color: white;
            font-size: 12px;
            margin: 0 !important;
          }
          & h4:nth-of-type(1){
            margin-top: 60px;
          }
          .item_download {
            margin-top: 30px;
            padding-left: 0 !important;
            img{
              display: block;
              width: 150px;
              height: 50px;
              margin: 0 auto;
            }
          }
          .wbg{
            background: linear-gradient(86deg, #013799 0%, #0071D6 100%);
          }
        }
        .right_item{
          width: calc(100vw);
          display: flex;
          justify-content: center;
          align-items: center;
          img{
            width: 100% !important;
            position: absolute;
            bottom: 0;
            margin-right: 0;
            height: 350px;
          }
        }
      }
      .inner2{
        padding: 0px calc((100% - 1200px) / 2) 50px;
        .inner2_bg{
          background: none;
        }
        div{
          width: 100%;
          padding: 0 20px;
        }
        .title{
          h3{
            width: 100%;
            font-size: 25px;
            text-align: center;
            margin-bottom: 0;
          }
            margin-bottom: 0;
        }
        .content{
          width: 100%;
          padding: 20px 0;
          h1{
            margin: 10px 0 20px;
            font-size: 20px;
            text-align:  center !important;
          }
        }
        .text_r{
          text-align: left !important;
          p{
            text-align: left !important;
          }
        }
        .content2{
          text-align: left;
          p{
          text-align: left;
          }
        }
      }
      .inner3{
        min-width: calc(100vw - 40px) !important;
        width: calc(100vw) !important;
        margin: 0 auto !important;
        padding: 50px 0px;
        div{
          width: 100%;
        }
        p{
          padding: 10px 0 20px;
        }
        h3{
          width: 100%;
          font-size: 25px;
          text-align: center;
          margin-bottom: 20px;
        }
        .content{
          flex-direction: column;
          margin-bottom: 0;
          h1{
            font-size: 20px;
          }
          img{
            width: 100% !important;
            margin: 0;
          }
          p{
            width: 80% !important;
            margin: 0 auto;
            font-size: 12px;
          }
        }
        .content2{
          width: 100%;
          align-items: center;
          justify-content: center;
          text-align: center !important;
          padding: 0 !important;
        }
        .left_bg{
          width: 30px;
          height: 50px;
          top: 40px;
        }
        .inner5_item{
          margin:  0 auto;
          padding: 0;
          display: flex;
          flex-direction: column;
          .content{
            flex: 1;
            width: 100%;
            margin: 20px 0;
          }
        }
        .carousel-button{
          padding: 0 10px;
          img{
            width: 10px;
          }
        }
        .carousel-container{
          height: 600px;
        }
        .carousel-wrapper{
          padding: 0 20px;
          flex-wrap: wrap;
          .carousel-item{
            width: 45%;
            margin: 0 5px;
          }
        }
        .carousel-item{
          width: 150px;
          height: 280px;
          img{
            width: 60px;
          }
          h1{
            font-size: 13px;
            height: 40px;
            padding: 0;
            line-height: 16px;
          }
          p{
            font-size: 12px;
            height: 90px;
            padding: 0 5px;
            line-height: 18px;
          }
        }
      }
      .inner4{
        min-width: calc(100vw - 40px) !important;
        width: calc(100vw - 40px) !important;
        margin: 0 auto !important;
        padding: 50px 0px;
        h1,h2,h3,p{
          text-align: center !important;
        }
        .flex{
          flex-direction: column;
        }
        .content{
          width: 100%;
          margin: 0 0 20px 0 !important;
          img{
            margin: 0 auto;
            // width: calc(100vw - 40px) !important;
          }
          div{
            width: calc(100vw - 40px) !important;
            p{
              font-size: 12px;
            }
          }
        }
        .title{
          width: 100%;
          margin-bottom: 20px;
        }
        .inner5_item{
          display: flex;
          flex-direction: column;
        }
      }
      .inner7{
        padding: 30px 20px 30px;
        position: relative;
        h3{
          text-align: center;
          margin-bottom: 30px;
          line-height: 30px;
        }
        .carousel-container{
          height: 320px;
        }
        .carousel-item{
          min-width: 150px !important;
          width: 45% !important;
          flex-direction: column;
          align-items: center !important;
          justify-content: center !important;
          h1{
            font-size: 12px;
            padding: 0 !important;
          }
          img{
            width: 50px !important;
            height: 50px !important;
            margin-bottom: 10px;
          }
          p{
            display: none;
          }
        }
        .carousel-button{
          position: absolute;
        }
      }
    }
  }
</style>
